import { Box } from '@mui/material'
import React from 'react'
import { ChatMessageContainer, ChatSidebar } from '../components'

const Chat = () => {
  return (
    <Box sx={{
        display: 'flex',
        height: 'calc(100vh - 80px)'
    }}>
        <ChatSidebar />
        <ChatMessageContainer />
    </Box>
  )
}

export default Chat