import * as React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

export default function CheckboxForm() {
  return (
    <FormGroup row>
      <FormControlLabel sx={{color: '#5D5C64'}} control={<Checkbox  defaultChecked />} label="Neu" />
      <FormControlLabel sx={{color: '#5D5C64'}} control={<Checkbox defaultChecked />} label="Warte auf Bestatigung" />
      <FormControlLabel sx={{color: '#5D5C64'}} control={<Checkbox />} label="Erledigt" />
      <FormControlLabel sx={{color: '#5D5C64'}} control={<Checkbox defaultChecked />} label="Warte auf Feedback" />
      <FormControlLabel sx={{color: '#5D5C64'}} control={<Checkbox  />} label="Police umgeteilt" />
    </FormGroup>
  );
}