import { Box } from '@mui/material'
import React from 'react'

const MessageContainer = () => {
  return (
    <Box sx={{
        flex: 11
    }}>

    </Box>
  )
}

export default MessageContainer