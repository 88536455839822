import { Box, Button, InputAdornment, TextField } from '@mui/material'
import React from 'react'
import CheckboxForm from '../components/mandatUbersicht/CheckboxForm'
import FilterCard from '../components/mandatUbersicht/FilterCard'
import { Search } from '@mui/icons-material'
import { color } from '../utils/theme'
import CustomTable from '../components/mandatUbersicht/CustomTable'

const MandatUbersicht = () => {
  return (
    <Box sx={{
      display: 'flex',
      gap: 5,
      flexDirection: 'column',
      backgroundColor: 'white',
      borderRadius: 3,
      p: 2
    }}>
      <Box
      sx={{
        display: 'flex',
        width: '100%',
        justifyContent: 'flex-end'
      }}
      >
        <CheckboxForm />
      </Box>
      <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}
      >
      <Box sx={{
          border: `1px solid ${color.textGrey}`,
          height: 'auto',
          p: ' 5px',
          display: 'flex',
          flexDirection: 'column',
          gap: '5px',
        }}>
          <FilterCard title={'Neu'} />
          <FilterCard title={'Warte auf Bestatigung'} />
          <FilterCard title={'Warte auf Feedback'} />
          <FilterCard title={'Erledigt'} />
        </Box>
        <TextField size='small' sx={{width: '17%'}}  placeholder='Gesellsschaft auswahlen'/>
        <TextField size='small' sx={{width: '17%'}}  placeholder='Broker auswahlen'/>
        <TextField size='small' sx={{width: '17%'}}  placeholder='Kanton auswahlen'/>
        <TextField size='small' sx={{width: '17%'}}  placeholder='Nationalitat auswahlen'/>
        <Button sx={{
          backgroundColor: '#E5EAF0',
          color: color.textGrey
        }} startIcon={<Search />} variant='contained'>Suchen</Button>
      </Box>
      <Box
      sx={{

      }}
      >
        <TextField size='small' InputProps={{
          startAdornment: <InputAdornment position='start'>
          <Search />
        </InputAdornment>
        }}  placeholder='Search...'/>
      </Box>
      <Box
      sx={{

      }}
      >
        <CustomTable />
      </Box>
    </Box>
  )
}

export default MandatUbersicht