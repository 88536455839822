import { Close } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'
import React from 'react'
import { color } from '../../utils/theme'

const FilterCard = ({title}) => {
  return (
    <Box sx={{
        display: 'flex',
        gap: '3px',
        p: '3px',
        width: 'fit-content',
        alignItems: 'center',
        color: color.textGrey,
        backgroundColor: '#F4F5F7',
        borderRadius: '4px',
        wordBreak: 'keep-all'
    }}>
        <Close fontSize='small' />
        <Typography>{title}</Typography>
    </Box>
  )
}

export default FilterCard