import { Box } from '@mui/material'
import React from 'react'
import { VertrageContentBox, VertrageHeaderBox } from '../components'

const Vertrage = () => {
  return (
    <Box sx={{m: 1, display: 'flex', flexDirection: 'column'}}>
        <VertrageHeaderBox />
        <Box sx={{
            height: 'calc(100vh - 350px)',
            overflowY: 'scroll',
            display: 'flex',
            flexDirection: 'column',
            gap: 5,
            mt: 5,
            mb: 10,
        }}>
            <VertrageContentBox />
            <VertrageContentBox />
            <VertrageContentBox />
            <VertrageContentBox />
            <VertrageContentBox />
            <VertrageContentBox />
            <VertrageContentBox />
            <VertrageContentBox />
            <VertrageContentBox />
        </Box>
    </Box>
  )
}

export default Vertrage