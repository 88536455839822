import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
// import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { ArrowForwardIos, Dashboard, Layers, Menu } from '@mui/icons-material';
import { Route, Routes, useNavigate } from 'react-router-dom';
import Vertrage from './Vertrage';
import MandatUbersicht from './MandatUbersicht';
import { IconButton } from '@mui/material';
import Chat from './Chat';

const drawerWidth = 240;

export default function MainPage() {
    const navigate = useNavigate()
    const [sidebarOpen, setSidebarOpen] = React.useState(true)
  return (
    <Box sx={{ display: 'flex', height: '100vh',}}>
      <CssBaseline /> 
      <Drawer
        sx={{
          width: sidebarOpen && drawerWidth,
          transition: 'width 0.2s ease-in-out',
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            backgroundColor: '#585858',
            color: 'white'
          },
        }}
        variant="persistent"
        anchor="left"
        open={sidebarOpen}
        onClose={() => setSidebarOpen(false)}
      >
        {/* <Toolbar /> */}
        <Divider sx={{
          mt: {
            md: 5,
            xl: 8,
          }
        }}/>
        <List>
        <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <Layers sx={{color: 'white'}} />
                </ListItemIcon>
                <ListItemText primary={'Dashboard'} />
              </ListItemButton>
            </ListItem>
            <Typography variant='h5' sx={{m: 2, my: {xs: 1, md: 2, xl: 3}}}>USERS</Typography>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <Dashboard sx={{color: 'white'}} />
                </ListItemIcon>
                <ListItemText primary={'Kunden'} />
              </ListItemButton>
            </ListItem>
            <Typography variant='h5' sx={{m: 2, mt: { xs: 2, md: 4, xl: 6}}}>VERTRAGE</Typography>
            <ListItem onClick={() => navigate('/vertrage')} disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <Dashboard sx={{color: 'white'}} />
                </ListItemIcon>
                <ListItemText primary={'Vertrage'} />
              </ListItemButton>
            </ListItem>
            <Typography variant='h5' sx={{m: 2, my: {xs: 1, md: 2, xl: 3}}}>OFFERTEN</Typography>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <Dashboard sx={{color: 'white'}} />
                </ListItemIcon>
                <ListItemText primary={'Offerte Anfragen'} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <Dashboard sx={{color: 'white'}} />
                </ListItemIcon>
                <ListItemText primary={'Offerte senden'} />
              </ListItemButton>
            </ListItem>
            <Typography variant='h5' sx={{m: 2, my: {xs: 1, md: 2, xl: 3}}}>MANDAT-UBERSICHT</Typography>
            <ListItem onClick={() => navigate('/mandat-ubersicht')} disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <Dashboard sx={{color: 'white'}} />
                </ListItemIcon>
                <ListItemText secondaryTypographyProps={
                  {
                    style: {
                      color: 'white'
                    }
                  }
                } secondary={'Mandat-Ubersicht'} />
                <ArrowForwardIos fontSize='small' sx={{ml: 1}}/>
              </ListItemButton>
            </ListItem>
        </List>
      </Drawer>
      <Box
        component="main"
        sx={{ flexGrow: 1, bgcolor: '#EDF0F9', height: '100%', overflow: 'hidden'}}
      >
        <Box sx={{
          height: 50,
          width: '100%',
          p: 1,
          mt:1,
          backgroundColor: 'white',
          display: 'flex',
          justifyContent: 'space-between'
        }}>
          <Typography variant='h6'>Vertrage</Typography>
          <IconButton onClick={() => setSidebarOpen(!sidebarOpen)}>
            <Menu />
          </IconButton>
        </Box>
        <Box padding={1}>
            <Routes>
                <Route path='' element={<Vertrage />}/>
                <Route path='vertrage' element={<Vertrage />}/>
                <Route path='mandat-ubersicht' element={<MandatUbersicht />}/>
                <Route path='plaudern' element={<Chat />}/>
            </Routes>
        </Box>
        
      </Box>
    </Box>
  );
}
