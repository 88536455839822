import { Email, LocationOn, Phone, Verified } from '@mui/icons-material'
import { Box, Button, Divider, Typography } from '@mui/material'
import React from 'react'
import { color } from '../../utils/theme'

const HeaderBox = () => {
  return (
    <Box sx={{
        width: '100%',
        borderRadius: 2,
        p: 2,
        backgroundColor: 'white'
    }}>
        <Box
         sx={{
          display: 'flex',
         }}
        >
          <Box sx={{
            flexGrow: 1,
            display: 'flex',
            gap: 2,
          }}>
            <img alt='Profile' style={{
              borderRadius: 10,
            }} src='https://images.pexels.com/photos/2182970/pexels-photo-2182970.jpeg?auto=compress&cs=tinysrgb&w=1600' width={'100px'} height={'150px'}/>
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2
            }}>
              <Box sx={{
                display: 'flex',
                gap: 1
              }}>
                <Typography sx={{
                  color: '#3F4847',
                  fontWeight: 600
                }}>Massaaro Carmine</Typography>
                <Verified color='success'/>
              </Box>
              <Box
                sx={{
                 display: 'flex' ,
                  gap: 2
                }}
              >
                <Box sx={{
                  display: 'flex',
                  gap: 1,
                  color: color.textGrey,
                  alignItems: 'center'
                }}>
                  <Email fontSize='small'/>
                  <Typography>massaro@outlook.com</Typography>
                </Box>
                <Box sx={{
                  display: 'flex',
                  gap: 1,
                  color: color.textGrey,
                  alignItems: 'center'
                }}>
                  <Phone fontSize='small'/>
                  <Typography>+38 455 51211</Typography>
                </Box>
                <Box sx={{
                  display: 'flex',
                  gap: 1,
                  color: color.textGrey
                }}>
                  <LocationOn />
                  <Typography>Glattbrugg, Zunstrasse 9a</Typography>
                </Box>
              </Box>
              <Box sx={{
                color: color.textGrey
              }}>
              <Typography>Geburtsdatum 05.12.1989</Typography>
              <Typography>registrieert 23.09.2020</Typography>
              </Box>
            </Box>
          </Box>
          <Box sx={{
            display: 'flex',
            alignItems: 'flex-start',
            gap: 2
          }}>
            <Button
            sx={{
              backgroundColor: '#FEF5DB',
              ':hover': {
                backgroundColor: '#A9A392',
                color: '#FEF5DB'
              },
              color: '#D3A93B',
              fontWeight: 600
            }}
            variant='contained'>Neur Vertrag Erfassen</Button>
            <Button
            sx={{
              backgroundColor: '#C7F8F5',
              ":hover": {
                backgroundColor: '#5BA2A4',
                color: '#C7F8F5',
              },
              color: '#5BA2A4',
              fontWeight: 600
            }}
            variant='contained'>Push Nachricht senden</Button>
          </Box>
        </Box>
        <Divider sx={{
          my: 2
        }}/> 
    </Box>
  )
}

export default HeaderBox