import { Add, ArrowForwardIos } from '@mui/icons-material'
import { Box, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material'
import React from 'react'

const CustomTable = () => {
  return (
   <Paper>
     <TableContainer sx={{maxHeight: 'calc(100vh - 470px)', overflow: 'scroll'}}>
        <Table stickyHeader size='small'>
            
            <TableHead>
                <TableCell></TableCell>
                <TableCell>id</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Lead Status</TableCell>
                <TableCell>Marketing Campaign</TableCell>
                <TableCell>Mandat</TableCell>
                <TableCell>Gresellschaften</TableCell>
                <TableCell>Broker</TableCell>
                <TableCell>Datum</TableCell>
                <TableCell>Email gesendet</TableCell>
                <TableCell>Actions</TableCell>
            </TableHead>
            <TableBody>
            <TableRow>
                    <TableCell>
                        <ArrowForwardIos sx={{fontSize: '12px'}}/>
                    </TableCell>
                    <TableCell sx={{fontSize: '12px'}}>121224</TableCell>
                    <TableCell sx={{fontSize: '12px'}}>Mikalhel Razanli</TableCell>
                    <TableCell sx={{fontSize: '12px'}}>SMS gesendet um neu zu unterzeichnen</TableCell>
                    <TableCell sx={{fontSize: '12px'}}></TableCell>
                    <TableCell sx={{fontSize: '12px'}}>PDF</TableCell>
                    <TableCell sx={{fontSize: '12px'}}>Helsana</TableCell>
                    <TableCell sx={{fontSize: '12px'}}>Carmine Massaro</TableCell>
                    <TableCell sx={{fontSize: '12px'}}>02.08.2023</TableCell>
                    <TableCell sx={{fontSize: '12px'}}>Keine Email gesendet</TableCell>
                    <TableCell>
                        <Box sx={{
                            backgroundColor: '#14C8BD',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderRadius: 2,
                            color: 'white',
                            userSelect: 'none',
                            cursor: 'pointer'
                        }}>
                            <Add fontSize='large'/>
                        </Box>
                    </TableCell>
                </TableRow>
            <TableRow>
                    <TableCell>
                        <ArrowForwardIos sx={{fontSize: '12px'}}/>
                    </TableCell>
                    <TableCell sx={{fontSize: '12px'}}>121224</TableCell>
                    <TableCell sx={{fontSize: '12px'}}>Mikalhel Razanli</TableCell>
                    <TableCell sx={{fontSize: '12px'}}>SMS gesendet um neu zu unterzeichnen</TableCell>
                    <TableCell sx={{fontSize: '12px'}}></TableCell>
                    <TableCell sx={{fontSize: '12px'}}>PDF</TableCell>
                    <TableCell sx={{fontSize: '12px'}}>Helsana</TableCell>
                    <TableCell sx={{fontSize: '12px'}}>Carmine Massaro</TableCell>
                    <TableCell sx={{fontSize: '12px'}}>02.08.2023</TableCell>
                    <TableCell sx={{fontSize: '12px'}}>Keine Email gesendet</TableCell>
                    <TableCell>
                        <Box sx={{
                            backgroundColor: '#14C8BD',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderRadius: 2,
                            color: 'white',
                            userSelect: 'none',
                            cursor: 'pointer'
                        }}>
                            <Add fontSize='large'/>
                        </Box>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>
                        <ArrowForwardIos sx={{fontSize: '12px'}}/>
                    </TableCell>
                    <TableCell sx={{fontSize: '12px'}}>121224</TableCell>
                    <TableCell sx={{fontSize: '12px'}}>Mikalhel Razanli</TableCell>
                    <TableCell sx={{fontSize: '12px'}}>SMS gesendet um neu zu unterzeichnen</TableCell>
                    <TableCell sx={{fontSize: '12px'}}></TableCell>
                    <TableCell sx={{fontSize: '12px'}}>PDF</TableCell>
                    <TableCell sx={{fontSize: '12px'}}>Helsana</TableCell>
                    <TableCell sx={{fontSize: '12px'}}>Carmine Massaro</TableCell>
                    <TableCell sx={{fontSize: '12px'}}>02.08.2023</TableCell>
                    <TableCell sx={{fontSize: '12px'}}>Keine Email gesendet</TableCell>
                    <TableCell>
                        <Box sx={{
                            backgroundColor: '#14C8BD',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderRadius: 2,
                            color: 'white',
                            userSelect: 'none',
                            cursor: 'pointer'
                        }}>
                            <Add fontSize='large'/>
                        </Box>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>
                        <ArrowForwardIos sx={{fontSize: '12px'}}/>
                    </TableCell>
                    <TableCell sx={{fontSize: '12px'}}>121224</TableCell>
                    <TableCell sx={{fontSize: '12px'}}>Mikalhel Razanli</TableCell>
                    <TableCell sx={{fontSize: '12px'}}>SMS gesendet um neu zu unterzeichnen</TableCell>
                    <TableCell sx={{fontSize: '12px'}}></TableCell>
                    <TableCell sx={{fontSize: '12px'}}>PDF</TableCell>
                    <TableCell sx={{fontSize: '12px'}}>Helsana</TableCell>
                    <TableCell sx={{fontSize: '12px'}}>Carmine Massaro</TableCell>
                    <TableCell sx={{fontSize: '12px'}}>02.08.2023</TableCell>
                    <TableCell sx={{fontSize: '12px'}}>Keine Email gesendet</TableCell>
                    <TableCell>
                        <Box sx={{
                            backgroundColor: '#14C8BD',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderRadius: 2,
                            color: 'white',
                            userSelect: 'none',
                            cursor: 'pointer'
                        }}>
                            <Add fontSize='large'/>
                        </Box>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>
                        <ArrowForwardIos sx={{fontSize: '12px'}}/>
                    </TableCell>
                    <TableCell sx={{fontSize: '12px'}}>121224</TableCell>
                    <TableCell sx={{fontSize: '12px'}}>Mikalhel Razanli</TableCell>
                    <TableCell sx={{fontSize: '12px'}}>SMS gesendet um neu zu unterzeichnen</TableCell>
                    <TableCell sx={{fontSize: '12px'}}></TableCell>
                    <TableCell sx={{fontSize: '12px'}}>PDF</TableCell>
                    <TableCell sx={{fontSize: '12px'}}>Helsana</TableCell>
                    <TableCell sx={{fontSize: '12px'}}>Carmine Massaro</TableCell>
                    <TableCell sx={{fontSize: '12px'}}>02.08.2023</TableCell>
                    <TableCell sx={{fontSize: '12px'}}>Keine Email gesendet</TableCell>
                    <TableCell>
                        <Box sx={{
                            backgroundColor: '#14C8BD',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderRadius: 2,
                            color: 'white',
                            userSelect: 'none',
                            cursor: 'pointer'
                        }}>
                            <Add fontSize='large'/>
                        </Box>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>
                        <ArrowForwardIos sx={{fontSize: '12px'}}/>
                    </TableCell>
                    <TableCell sx={{fontSize: '12px'}}>121224</TableCell>
                    <TableCell sx={{fontSize: '12px'}}>Mikalhel Razanli</TableCell>
                    <TableCell sx={{fontSize: '12px'}}>SMS gesendet um neu zu unterzeichnen</TableCell>
                    <TableCell sx={{fontSize: '12px'}}></TableCell>
                    <TableCell sx={{fontSize: '12px'}}>PDF</TableCell>
                    <TableCell sx={{fontSize: '12px'}}>Helsana</TableCell>
                    <TableCell sx={{fontSize: '12px'}}>Carmine Massaro</TableCell>
                    <TableCell sx={{fontSize: '12px'}}>02.08.2023</TableCell>
                    <TableCell sx={{fontSize: '12px'}}>Keine Email gesendet</TableCell>
                    <TableCell>
                        <Box sx={{
                            backgroundColor: '#14C8BD',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderRadius: 2,
                            color: 'white',
                            userSelect: 'none',
                            cursor: 'pointer'
                        }}>
                            <Add fontSize='large'/>
                        </Box>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>
                        <ArrowForwardIos sx={{fontSize: '12px'}}/>
                    </TableCell>
                    <TableCell sx={{fontSize: '12px'}}>121224</TableCell>
                    <TableCell sx={{fontSize: '12px'}}>Mikalhel Razanli</TableCell>
                    <TableCell sx={{fontSize: '12px'}}>SMS gesendet um neu zu unterzeichnen</TableCell>
                    <TableCell sx={{fontSize: '12px'}}></TableCell>
                    <TableCell sx={{fontSize: '12px'}}>PDF</TableCell>
                    <TableCell sx={{fontSize: '12px'}}>Helsana</TableCell>
                    <TableCell sx={{fontSize: '12px'}}>Carmine Massaro</TableCell>
                    <TableCell sx={{fontSize: '12px'}}>02.08.2023</TableCell>
                    <TableCell sx={{fontSize: '12px'}}>Keine Email gesendet</TableCell>
                    <TableCell>
                        <Box sx={{
                            backgroundColor: '#14C8BD',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderRadius: 2,
                            color: 'white',
                            userSelect: 'none',
                            cursor: 'pointer'
                        }}>
                            <Add fontSize='large'/>
                        </Box>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>
                        <ArrowForwardIos sx={{fontSize: '12px'}}/>
                    </TableCell>
                    <TableCell sx={{fontSize: '12px'}}>121224</TableCell>
                    <TableCell sx={{fontSize: '12px'}}>Mikalhel Razanli</TableCell>
                    <TableCell sx={{fontSize: '12px'}}>SMS gesendet um neu zu unterzeichnen</TableCell>
                    <TableCell sx={{fontSize: '12px'}}></TableCell>
                    <TableCell sx={{fontSize: '12px'}}>PDF</TableCell>
                    <TableCell sx={{fontSize: '12px'}}>Helsana</TableCell>
                    <TableCell sx={{fontSize: '12px'}}>Carmine Massaro</TableCell>
                    <TableCell sx={{fontSize: '12px'}}>02.08.2023</TableCell>
                    <TableCell sx={{fontSize: '12px'}}>Keine Email gesendet</TableCell>
                    <TableCell>
                        <Box sx={{
                            backgroundColor: '#14C8BD',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderRadius: 2,
                            color: 'white',
                            userSelect: 'none',
                            cursor: 'pointer'
                        }}>
                            <Add fontSize='large'/>
                        </Box>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>
                        <ArrowForwardIos sx={{fontSize: '12px'}}/>
                    </TableCell>
                    <TableCell sx={{fontSize: '12px'}}>121224</TableCell>
                    <TableCell sx={{fontSize: '12px'}}>Mikalhel Razanli</TableCell>
                    <TableCell sx={{fontSize: '12px'}}>SMS gesendet um neu zu unterzeichnen</TableCell>
                    <TableCell sx={{fontSize: '12px'}}></TableCell>
                    <TableCell sx={{fontSize: '12px'}}>PDF</TableCell>
                    <TableCell sx={{fontSize: '12px'}}>Helsana</TableCell>
                    <TableCell sx={{fontSize: '12px'}}>Carmine Massaro</TableCell>
                    <TableCell sx={{fontSize: '12px'}}>02.08.2023</TableCell>
                    <TableCell sx={{fontSize: '12px'}}>Keine Email gesendet</TableCell>
                    <TableCell>
                        <Box sx={{
                            backgroundColor: '#14C8BD',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderRadius: 2,
                            color: 'white',
                            userSelect: 'none',
                            cursor: 'pointer'
                        }}>
                            <Add fontSize='large'/>
                        </Box>
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
        <caption style={{display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center'}}>
                <Pagination count={65} variant='outlined' shape='rounded'/>
                <TablePagination /></caption>
    </TableContainer>
   </Paper>
  )
}

export default CustomTable