import React from 'react';
import { CloudUpload, DeleteForever, Edit } from '@mui/icons-material'
import { Box, Button, Typography } from '@mui/material'

const ContentBox = () => {
  return (
    <Box sx={{
        width: '70%',
        borderRadius: 2,
        p: 2,
        backgroundColor: 'white'
    }}>
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 6
        }}>
            <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start'
        }}>
                <Box>
                    <Typography variant='h6'>Kransakasse / GroupeMutuesl</Typography>
                    <Typography variant='h6'>Vertarsummer:552454</Typography>
                    <Typography variant='body1'>18.11.2021</Typography>
                </Box>
                <Box sx={{
                    display: 'flex',
                    gap: 2
                }}>
                    <Button sx={{
                        bgcolor: '#F44D61',
                        ":hover": {
                            bgcolor: '#A23340'
                        }
                    }} startIcon={<DeleteForever />} variant='contained'>Loschen</Button>
                    <Button sx={{
                        bgcolor: '#FFA30E',
                        ":hover": {
                            bgcolor: '#AA6C09'
                        }
                    }} startIcon={<Edit />} variant='contained'>Bearbelten</Button>
                    <Button sx={{
                        bgcolor: '#1AC5BD',
                        ":hover": {
                            bgcolor: '#11837E'
                        }
                    }} startIcon={<CloudUpload />} variant='contained'>Datei hinzufugen</Button>
                </Box>
            </Box>
            <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end'
        }}>
                <Box>
                    <img alt='png icon' style={{marginLeft: '-7px'}} width={'60px'} src='https://th.bing.com/th/id/OIP.YVlRSi0UvFlJWMBQAhwCCQHaHa?rs=1&pid=ImgDetMain'/>
                </Box>
                <Box sx={{
                    display: 'flex',
                    gap: 1
                }}>
                    <Box sx={{
                        p: '11px 11px 8px 11px',
                        bgcolor: '#F2F7FB',
                        borderRadius: '3px',
                        cursor: 'pointer',
                        ":hover": {
                            backgroundColor: '#E7E9E7'
                        },

                    }}>
                    <i style={{fontSize: '18px', color: '#6795FF'}} class=" fa fa-solid fa-binoculars"></i>
                    </Box>
                    <Box sx={{
                        p: '11px 11px 8px 11px',
                        bgcolor: '#F2F7FB',
                        borderRadius: '3px',
                        cursor: 'pointer',
                        ":hover": {
                            backgroundColor: '#E7E9E7'
                        },

                    }}>
                     <i style={{fontSize: '18px', color: '#6795FF'}} class=" fa fa-trash"></i>
                    </Box>
                </Box>
            </Box>
        </Box>
    </Box>
  )
}

export default ContentBox