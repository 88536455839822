import { NotificationAdd } from '@mui/icons-material'
import { Box, Divider, List, ListItem, ListItemIcon, ListItemText, TextField, Typography } from '@mui/material'
import React from 'react'

const Sidebar = () => {
  return (
    <Box sx={{
        p: 2,
        backgroundColor: 'white',
        borderRadius: 3,
        flex: 3, 
        height: '100%'
    }}>
        <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            mb: 1,
            alignItems: 'center'
        }}>
            <Typography>Carmine Massarao</Typography>
            <Divider sx={{height: '15px', border: '1px solid grey', mx: 1}} orientation='vertical'/>
            <Typography>Carmine Massarao</Typography>
        </Box>
        <Divider sx={{my: 1}} />
        <TextField size='small' fullWidth />
        <List>
            <ListItem sx={{gap: 2, textOverflow: 'clip'}}>
            <ListItemIcon>
                <NotificationAdd />
            </ListItemIcon>
            <ListItemText primary={'Alice Corradi | Carmine Massaro'} />
            <ListItemText secondary={'20 mins ago'} />
            </ListItem>
            <ListItem sx={{gap: 2}}>
            <ListItemIcon>
                <NotificationAdd />
            </ListItemIcon>
            <ListItemText primary={'Alice Corradi | Carmine Massaro'} />
            <ListItemText secondary={'20 mins ago'} />
            </ListItem>
            <ListItem sx={{gap: 2}}>
            <ListItemIcon>
                <NotificationAdd />
            </ListItemIcon>
            <ListItemText primary={'Alice Corradi | Carmine Massaroweweweweweegsd'} />
            <ListItemText secondary={'20 mins ago'} />
            </ListItem>
            <ListItem sx={{gap: 2}}>
            <ListItemIcon>
                <NotificationAdd />
            </ListItemIcon>
            <ListItemText primary={'Alice Corradi | Carmine Massaro'} />
            <ListItemText secondary={'20 mins ago'} />
            </ListItem>
            <ListItem sx={{gap: 2}}>
            <ListItemIcon>
                <NotificationAdd />
            </ListItemIcon>
            <ListItemText primary={'Alice Corradi | Carmine Massaro'} />
            <ListItemText secondary={'20 mins ago'} />
            </ListItem>
        </List>
    </Box>
  )
}

export default Sidebar